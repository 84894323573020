// Vue core
import Vue from 'vue'
import VueHead from 'vue-head'
import App from './App.vue'
import './registerServiceWorker'
import {router} from './router'
import store from './store'
import axios from 'axios'

// SSO
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'
import kcConfig from '@/misc/keyCloakConfig'

// CSS
import Buefy from 'buefy';

// Utilities
import '@/misc/utils'
import '@/misc/filters'

window.HTTP = axios

Vue.use(VueHead, {
  separator: '',
  complement: ''
})

// Setup SSO auth 
Vue.use(VueKeycloakJs, {
  init: {
    onLoad: kcConfig.onLoad,
    silentCheckSsoRedirectUri: kcConfig.silentCheckSsoRedirectUri
  },
  config: {
    url: kcConfig.url,
    clientId: kcConfig.clientId,
    realm: kcConfig.realm
  }
})

// Setup CSS Lib
Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultDialogConfirmText: 'Aceptar',
  defaultDialogCancelText: 'Cancelar',
  defaultFieldLabelPosition: 'on-border',
})

// Metadata handler
Vue.use(VueHead)

// Filters
Vue.filter('currency', function (value) {  
  return (value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  }); 
})

// Render App
new Vue({
  router,
  store,
  render: h => h(App),
  watch: {
    '$keycloak.ready'(isIt){
      this.$store.commit('setAppLoading', !isIt)
    }
  },
  created(){
    let isDarkthemeActive = localStorage.getItem('mw.app.darkTheme')

    if( isDarkthemeActive == 1 )
      this.$store.commit('setAppDarkTheme', true)

    // Set apikey as default for all requests
    axios.defaults.headers.common['x-api-key'] = this.$store.state.backendAPI.apiKey
  }
}).$mount('#app')
