window.foo = ()=>{
    alert('bar')
}

window.capitalize = (value) => {
    if (!value) return '';
    let v = value.toString()
    return v.charAt(0).toUpperCase() + v.slice(1).toLowerCase()
}

window.debounce = (fn, time=300)=>{
    if( window.__timeOut )
        clearTimeout( window.__timeOut )

    window.__timeOut = setTimeout(()=>{
        fn()
    }, time)
}

window.getUniqueId = ()=>{
    return (Math.random() + 1).toString(36).substring(7) + 
        (Math.random() + 1).toString(36).substring(7) +
        (Math.random() + 1).toString(36).substring(7) +
        (Math.random() + 1).toString(36).substring(7) +
        (Math.random() + 1).toString(36).substring(7) +
        (Math.random() + 1).toString(36).substring(7)
}

window.parseJwt = (token)=>{
    try{
        return JSON.parse(
            atob( token )
        )
    }catch(e){
        return null
    }
}