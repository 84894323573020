<template>
    <div class="box overflow-x-auto">
        <b-field label="Registros a mostrar">
            <b-select placeholder="Selecciona" v-model="logEntriesPerPage">
                <option value="5">Últimos 5</option>
                <option value="10">Últimos 10</option>
                <option value="25">Últimos 25</option>
                <option value="50">Últimos 50</option>
                <option value="100">Últimos 100</option>
            </b-select>
            <div class="ml-auto d-block">
                <b-button @click="getCatalogs" class="mr-3" type="is-primary" icon-left="refresh" :loading="isLoading">Actualizar</b-button>
                <b-button @click="isModalAddOpen = true" type="is-primary" icon-left="plus" :disabled="isLoading">Nuevo</b-button>
            </div>
        </b-field>
        <b-table :data="catalogList" :loading="isLoading" default-sort="createdAt" default-sort-direction="desc">
             <b-table-column field="userEmail" label="Usuario" v-slot="props" centered searchable>
                <i class="is-size-7">
                    {{ props.row.userEmail }}
                </i>
            </b-table-column>

            <b-table-column field="catalogId" label="UUID" v-slot="props" centered searchable sortable>
                <i class="is-size-7">
                    {{ props.row.catalogId }}
                </i>
            </b-table-column>

            <b-table-column field="catalogName" label="Nombre" v-slot="props" centered searchable sortable>
                <span class="is-size-7">
                    {{ props.row.catalogName }}
                </span>
            </b-table-column>

            <b-table-column field="version" label="Versión" v-slot="props" centered searchable sortable>
                <span class="tag">
                    {{ props.row.version }}
                </span>
            </b-table-column>

            <b-table-column field="createdAt" label="Fecha de creación" v-slot="props" sortable centered>
                <span class="tag">
                    <b-icon icon="cloud-arrow-up"/>&nbsp;{{ props.row.createdAt }}
                </span>
            </b-table-column>

            <b-table-column label="Detalles" v-slot="props" centered>
                <router-link :to="`/catalogos/orders/${props.row.catalogId}`">
                    <b-button icon-right="eye" type="is-light is-primary" title="Ver detalles" />
                </router-link>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">Nada para mostrar</div>
            </template>
        </b-table>
        <b-modal
            v-model="isModalAddOpen"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            close-button-aria-label="Close"
            aria-modal>
            <form @submit.prevent="createNew">
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Registrar nombre de catálogo</p> 
                    </header>
                    <div class="modal-card-body">
                            <b-field label="Nombre del catálogo">
                            <b-input name="name" placeholder="Escribe el nombre.." required/>
                        </b-field>
                    </div>
                    <footer class="modal-card-foot">
                        <b-button class="d-block ml-auto" type="is-primary" native-type="submit">
                            Guardar
                        </b-button>
                    </footer>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
export default {
    mounted(){
        this.getCatalogs()
    },
    data() {
        return {
            catalogList: [],
            isLoading: false,
            logEntriesPerPage: 100,
            isModalAddOpen: false,
        }
    },
    watch: {
        logEntriesPerPage(){
            window.debounce(this.getCatalogs, 300)
        }
    },
    methods: {
        createNew(ev){
            let form = ev.target
            let catalogName = form.querySelector('[name="name"]').value
            this.isLoading = true

            this.$store.dispatch('createCatalog', catalogName)
            .then(response=>{
                if(response.status == 201){
                    this.getCatalogs()
                    form.reset()
                    this.isModalAddOpen = false
                    this.$buefy.toast.open({
                        message: '¡Nombre de catálogo registrado!',
                        type: 'is-success'
                    })
                }
            }).catch(err=>{
                console.error(err)
                this.$buefy.toast.open({
                    message: `Error: ${err.toString()}`,
                    type: 'is-danger'
                })
            })
            .finally(()=>this.isLoading=false)
        },
        getCatalogs(){
            this.isLoading = true
            this.$store.dispatch(
                'getCatalogList', 
                `?itemslimitnumber=${this.logEntriesPerPage}`
            )
            .then(res=>{
                let data = res.data
                this.catalogList = data.catalogs
            })
            .catch(err=>{
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>{
                this.isLoading = false
            })
        },
        getS3Link(item){
            this.$buefy.toast.open({
                message: 'Estamos obteniendo la liga del archivo...',
                position: 'is-bottom-right'
            })
            this.$store.dispatch('getS3ofUploadedTemplate', item.keyfilenameId)
            .then(res=>{
                let data = res.data
                this.$buefy.snackbar.open({
                    message: `¡Listo!, ya tenemos tu archivo (${item.keyfilenameId.substr(0,6)})`,
                    position: 'is-bottom-right',
                    actionText: 'Descargar',
                    cancelText: 'Cancelar',
                    indefinite: true,
                    type: 'is-warning',
                    onAction: () => {
                        window.open( data.s3link )
                    }
                })
            })
            .catch(err=>{
                console.warning(err)
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
        },
        _getStatusDisplay(status){
            return {
                'PROCESSING': 'Procesando',
                'PROCESSED': 'Procesado',
                'ERROR': 'Error',
                'PROCESSEDWITHERROR': 'Incompleto',
            }[status || 'PROCESSING']
        }
    }
}
</script>

<style>

</style>