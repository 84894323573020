<template>
    <div>
        <div class="box py-6 px-6">
            <div class="columns">
                <div class="column is-3 d-flex">
                    <label class="d-block m-auto">
                        <small>
                            <b>
                                ¿Qué plantilla quieres descargar?
                            </b>
                        </small>
                    </label>
                </div>
                <div class="column is-3">
                    <b-field>
                        <b-radio v-model="typeOfTemplate" native-value="default">
                            Carga de artículos (layout)
                        </b-radio>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field>
                        <b-radio v-model="typeOfTemplate" native-value="motivale">
                            Pre-carga de artículos desde <b>Motivale-eRewards</b>
                        </b-radio>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field>
                        <b-radio v-model="typeOfTemplate" native-value="inventory">
                            Carga de inventarios (layout)
                        </b-radio>
                    </b-field>
                </div>
            </div>
        </div>
        <div class="box mb-6" v-if="typeOfTemplate">            
            <div class="columns">
            <div v-if="typeOfTemplate == 'default'" class="column is-12">
                <label>
                    <small>
                        <b>Plantilla de carga de artículos (layout)</b>
                    </small>
                </label>
                <a href="/static/plantilla_products.xlsx" class="w-100 d-flex has-background-primary-light px-3 py-3">
                    <div class="m-auto">
                        <figure class="image is-128x128 pb-3 mb-3">
                            <img :src="require('@/assets/icons/list.svg')">
                        </figure>
                        <b-button type="is-primary">Descargar</b-button>
                    </div>
                </a>
            </div>
            <div v-else-if="typeOfTemplate == 'motivale'" class="column is-12">
                <label class="d-block mb-5">
                    <small>
                        <b>Plantilla pre-carga de artículos desde Motivale-eRewards</b>
                    </small>
                </label>
                <b-field label="Buscar catálogo">
                    <b-autocomplete
                        :data="catalogsList"
                        placeholder="Buscar por nombre o ID"
                        :icon="!catalog ? 'magnifying-glass' : 'user'"
                        clearable
                        field="name"
                        :loading="isLoadingCatalogs"
                        @typing="getCatalogAutocomplete"
                        @select="option => catalog = option">
                        <template #empty>Sin resulados 🤔</template>
                        <template slot-scope="props">
                            <div class="media">
                                <div class="media-left">
                                    <b-icon icon="book" type="is-small"/>
                                </div>
                                <div class="media-content">
                                    <b>{{ props.option.type }}:{{ props.option.id }}</b> | {{ props.option.name }}
                                </div>
                            </div>
                        </template>
                    </b-autocomplete>
                </b-field>

                <b-message type="is-primary" v-if="catalogItemsTotal > 0" class="mt-5">
                    👇 Selecciona alguno de los <b>{{ catalogItemsTotal }}</b> artículos para pre-cargar la plantilla. Usa los checks ☑️ de la izquierda.
                </b-message>

                <b-table :data="catalogItems" :loading="isLoadingCatalogItems" :checked-rows.sync="checkedItems" class="overflow-x-auto midget-table" v-show="catalog" checkable sticky-header>
                    <b-table-column field="productCuCode" label="CU" v-slot="props" centered searchable sortable>
                        <span class="tag">
                            {{ props.row.productCuCode }}
                        </span>
                    </b-table-column>

                    <b-table-column field="productSku" label="SKU" v-slot="props" centered searchable sortable>
                        <small v-if="props.row.productSku">
                            {{ props.row.productSku }}
                        </small>
                    </b-table-column>

                    <b-table-column field="productName" label="Artículo" v-slot="props" centered searchable sortable>
                        <small>{{ props.row.productName }}</small>
                    </b-table-column>

                    <b-table-column field="productCategoryName" label="Categoría" v-slot="props" centered searchable sortable>
                        <b class="is-size-7">
                            {{ props.row.productCategoryName }}
                        </b>
                    </b-table-column>

                    <b-table-column field="price" label="Precio" v-slot="props" centered sortable>
                        <span class="tag" :title="props.row.price">
                            {{ props.row.price | currency }}
                        </span>
                    </b-table-column>

                    <b-table-column field="joy_brand_name" label="Marca Joy" v-slot="props" centered sortable>
                        <span class="tag" :title="props.row.joy_brand_name">
                            {{ props.row.joy_brand_name }}
                        </span>
                    </b-table-column>

                    <template #empty>
                        <div class="has-text-centered">Nada para mostrar</div>
                    </template>
                </b-table>

                <div v-if="hasCheckedItems" class="mt-6">
                    <b-message :title="`Seleccionaste ${checkedItemsTotal} artículos`" type="is-warning" :closable="false">
                        <b-table :data="checkedItems" :checked-rows.sync="checkedItems" class="overflow-x-auto midget-table" checkable sticky-header>
                            <b-table-column field="productCuCode" label="CU" v-slot="props" centered searchable sortable>
                                <span class="tag">
                                    {{ props.row.productCuCode }}
                                </span>
                            </b-table-column>

                            <b-table-column field="productSku" label="SKU" v-slot="props" centered searchable sortable>
                                <small v-if="props.row.productSku">
                                    {{ props.row.productSku }}
                                </small>
                            </b-table-column>

                            <b-table-column field="productName" label="Artículo" v-slot="props" centered searchable sortable>
                                <small>{{ props.row.productName }}</small>
                            </b-table-column>

                            <b-table-column field="productCategoryName" label="Categoría" v-slot="props" centered searchable sortable>
                                <b class="is-size-7">
                                    {{ props.row.productCategoryName }}
                                </b>
                            </b-table-column>

                            <b-table-column field="price" label="Precio" v-slot="props" centered sortable>
                                <span class="tag" :title="props.row.price">
                                    {{ props.row.price | currency }}
                                </span>
                            </b-table-column>

                            <b-table-column field="joy_brand_name" label="Marca Joy" v-slot="props" centered sortable>
                                <span class="tag" :title="props.row.joy_brand_name">
                                    {{ props.row.joy_brand_name }}
                                </span>
                            </b-table-column>

                            <template #empty>
                                <div class="has-text-centered">Nada para mostrar</div>
                            </template>
                        </b-table>
                        <br>
                        ⚠️ Revisa la plantilla antes de subirla. No todos los campos están completos y podría haber algunos con información incorrecta.
                        <b-button @click="getPreloadedTemplate" class="d-block ml-auto" type="is-primary" icon-left="download" :loading="isLoadingPreloadedTemplate">
                            Descargar plantilla
                        </b-button>
                    </b-message>
                </div>
            </div>
            <div v-else-if="typeOfTemplate == 'inventory'" class="column is-12">
                <label>
                    <small>
                        <b>Plantilla de carga de inventario (layout)</b>
                    </small>
                </label>
                <a href="/static/plantilla_inventory.xlsx" class="w-100 d-flex has-background-primary-light px-3 py-3">
                    <div class="m-auto">
                        <figure class="image is-128x128 pb-3 mb-3">
                            <img :src="require('@/assets/icons/inventory.svg')">
                        </figure>
                        <b-button type="is-primary">Descargar</b-button>
                    </div>
                </a>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            typeOfTemplate: null,
            isLoadingCatalogs: false,
            isLoadingCatalogItems: false,
            isLoadingPreloadedTemplate: false,
            catalogsList: [],
            catalog: null,
            catalogItems: [],
            checkedItems: [],
        }
    },
    computed: {
        catalogItemsTotal(){
            return this.catalogItems.length
        },
        checkedItemsTotal(){
            return this.checkedItems.length 
        },
        hasCheckedItems(){
            return this.checkedItemsTotal > 0
        },
        checkedItemsFormatted(){
            return this.checkedItems.map((item)=>{
                return {
                    productCatalogId: item.productCatalogId || null, 
                    productCategoryId: item.productCategoryId || null, 
                    productCategoryName: (item.productCategoryName || '').trim() || null, 
                    sapCode: (item.sapCode || '').trim() || null, 
                    productSku: (item.productSku || '').trim() || null,
                    productName: (item.productName || '').trim() || null, 
                    description: (item.description || '').trim() || null, 
                    pricePoints: item.pricePoints || null, 
                    price: item.price || null, 
                    logistics: item.logistics || null, 
                    fee: item.fee || null, 
                    integratedPrice: item.integratedPrice || null, 
                    productLine: (item.productLine || '').trim() || null, 
                    productType: (item.productType || '').trim() || null, 
                    max_per_user: item.max_per_user || null, 
                    per_user_validation: item.per_user_validation || null, 
                    always_on: item.always_on || null, 
                    expires: item.expires || null, 
                    instructions: (item.instructions || '').trim() || null, 
                    total: item.total || null, 
                    prize_type: (item.prize_type || '').trim() || null, 
                    detail_file: (item.detail_file || '').trim() || null, 
                    back_file: (item.back_file || '').trim() || null, 
                    email_image: (item.email_image || '').trim() || null,
                    brand_id: item.joy_brand_id || null,
                }
            })
        }
    },
    watch: {
        catalog(c){
            if( !c )return this.reset()

            this.getCatalogItems()
        }
    },
    methods: {
        getCatalogAutocomplete(term){
            window.debounce(()=>{
                if (!term.length)
                    return this.catalogsList = []
                
                this.isLoadingCatalogs = true
                this.$store.dispatch('getCatalogAutocompleteList', term)
                .then((res) => {
                    this.catalogsList = []
                    res.data.data.forEach((item) => this.catalogsList.push(item))
                })
                .catch((err) => {
                    this.catalogsList = []
                    this.$buefy.toast.open({
                        type: 'is-danger',
                        message: 'Hubo un error: ' + err.toString(),
                        position: 'is-bottom-right'
                    })
                })
                .finally(() => {
                    this.isLoadingCatalogs = false
                })
            })
        },
        getCatalogItems(id){
            let catalogId = id || this.catalog ? this.catalog.id : null
            let catalogType = this.catalog ? this.catalog.type : null

            window.debounce(()=>{
                this.isLoadingCatalogItems = true
                this.$store.dispatch('getCatalogItemList', {
                    catalogId,
                    catalogType
                })
                .then((res) => {
                    this.catalogItems = []
                    res.data.data.forEach((item) => this.catalogItems.push(item))
                })
                .catch((err) => {
                    this.catalogItems = []
                    this.$buefy.toast.open({
                        type: 'is-danger',
                        message: 'Hubo un error: ' + err.toString(),
                        position: 'is-bottom-right'
                    })
                })
                .finally(() => {
                    this.isLoadingCatalogItems = false
                })
            })
        },
        reset(){
            this.catalogItems = []
            this.catalog = null
            //this.checkedItems = []
        },
        getPreloadedTemplate(){
            this.isLoadingPreloadedTemplate = true
            this.$buefy.toast.open({
                message: 'Estamos obteniendo la liga del archivo...',
                position: 'is-bottom-right'
            })
            this.$store.dispatch('getPreloadedTemplate', this.checkedItemsFormatted)
            .then(res=>{
                window.open( res.data.s3linktemplate )
            })
            .catch(err=>{
                console.warn(err)
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>this.isLoadingPreloadedTemplate = false)
        },
    }
}
</script>

<style scoped>
.midget-table{
    max-height: 60vh;
}
</style>