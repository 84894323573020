import Vue from 'vue'
import VueRouter from 'vue-router'
// Global
import LoginView from '../views/LoginView'
import NotFoundView from '../views/NotFoundView'
// Dashboard
import TablaHistorico from '../components/TablaHistorico'
import TablaMovimientoHistorico from '../components/TablaMovimientoHistorico'
import TablaOrders from '../components/TablaOrders'
import GenerarReporte from '../components/GenerarReporte'
import CatalogosList from '../components/CatalogosList'
import CargarPlantilla from '../components/CargarPlantilla'
import DescargarPlantilla from '../components/DescargarPlantilla'
import ReporteLdgList from '../components/ReporteLdgList'
import ReporteSL from '../components/ReporteSL'
import ReporteInventario from '../components/ReporteInventario'


Vue.use(VueRouter)

const routes = [
  // Dashboard Routes
  {
    path: '/',
    name: 'dashboard',
    component: TablaHistorico,
    meta: {
      requiresAuth: true,
      viewTitle: 'Histórico',
      viewSubtitle: 'Bitácora de movimientos'
    },
  },
  {
    path: '/historico/movimiento/:id',
    name: 'movimientoHistorico',
    component: TablaMovimientoHistorico,
    meta: (route)=>({
      requiresAuth: true,
      viewTitle: `Detalles de movimiento`,
      viewSubtitle: `ID: ${route.params.id}`
    }),
  },
  {
    path: '/catalogos/orders/:id',
    name: 'catalogsOrders',
    component: TablaOrders,
    meta: (route)=>({
      requiresAuth: true,
      viewTitle: `Detalles de órdenes`,
      viewSubtitle: `ID: ${route.params.id}`
    }),
  },
  {
    path: '/generar-reporte',
    name: 'generarReporte',
    component: GenerarReporte,
    meta: {
      requiresAuth: true,
      viewTitle: 'Generar reporte',
      viewSubtitle: 'Reporte de movimientos'
    },
  },
  {
    path: '/catalogos',
    name: 'catalogos',
    component: CatalogosList,
    meta: {
      requiresAuth: true,
      viewTitle: 'Catálogos',
      viewSubtitle: 'Administración de nombres de catálogos'
    },
  },
  {
    path: '/cargar-plantilla',
    name: 'cargarPlantilla',
    component: CargarPlantilla,
    meta: {
      requiresAuth: true,
      viewTitle: 'Cargar Plantilla',
      viewSubtitle: 'Carga datos de productos o inventarios'
    },
  },
  {
    path: '/descargar-plantilla',
    name: 'descargarPlantilla',
    component: DescargarPlantilla,
    meta: {
      requiresAuth: true,
      viewTitle: 'Descargar plantilla',
      viewSubtitle: 'Archivos para cargar catálogos o inventarios'
    },
  },
  {
    path: '/reporte-sl',
    name: 'reporteSL',
    component: ReporteSL,
    meta: {
      requiresAuth: true,
      viewTitle: 'Reporte SL',
      viewSubtitle: 'Archivo de reporte shipping list'
    },
  },
  {
    path: '/reporte-ldg-list',
    name: 'reporteLdgList',
    component: ReporteLdgList,
    meta: {
      requiresAuth: true,
      viewTitle: 'Reporte LDG List',
      viewSubtitle: 'Archivo de reporte shipping list'
    },
  },
  {
    path: '/reporte-inventario',
    name: 'reporteInventario',
    component: ReporteInventario,
    meta: {
      requiresAuth: true,
      viewTitle: 'Reporte Inventario',
      viewSubtitle: 'Archivo de reporte de inventario'
    },
  },
  // Global routes
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  { 
    path: '/:catchAll(.*)', 
    component: NotFoundView,
    name: '404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Middleware
const asyncBeforeEachHandler = async (to, from, next) => {

  // Wait until KC is ready
  while (router.app.$keycloak.createLoginUrl === null) {
    await new Promise(resolve => setTimeout(resolve, 100))
  }

  let authenticated = router.app.$keycloak.authenticated
  let resourceAccess = router.app.$keycloak.resourceAccess

  let hasAdminAccess = resourceAccess && 
    resourceAccess[process.env.VUE_APP_SSO_CLIENT_ID] && 
    resourceAccess[process.env.VUE_APP_SSO_CLIENT_ID].roles.indexOf('admin') !== -1

  if (to.matched.some(route => route.meta.requiresAuth)) {
    // next()
    if ( authenticated && hasAdminAccess )
      next()
    else
      next({name: 'login'}) // Force redirect to login
  }else{
    if( to.name == 'login' && ( authenticated && hasAdminAccess) )
      next({name: 'dashboard'}) // Force redirect to dashboard
    else
      next()
  }
}

router.beforeEach(asyncBeforeEachHandler)

export {
  router,
  routes,
  asyncBeforeEachHandler,
}
