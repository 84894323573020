import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app: {
      isLoading: true,
      isDarkThemeActive: false,
      isSideMenuOpen: false,
    },
    backendAPI: {
      apiKey: process.env.VUE_APP_BACKEND_API_KEY,
      rootUrl: process.env.VUE_APP_BACKEND_API_URL,
      services: {
        uploadCatalogTemplate: {
          url: '/files/'
        },
        uploadedTemplatesLog: {
          url: '/files'
        },
        uploadedTemplateLogDetails: {
          url: '/files'
        },
        s3LinkOfUploadedTemplate: {
          url: '/s3link'
        },
        preLoadedTemplate: {
          url: '/template'
        },
        reportGenerator: {
          url: '/report/BeginDate/{start}/EndDate/{end}'
        },
        catalogs: {
          get: {
            url: '/catalogs/all' 
          },
          put: {
            url: '/catalogs/' 
          },
        }
      },
    },
    motivaleRewardsAPI: {
      rootUrl: process.env.VUE_APP_MOTIVALE_EREWARDS_API_URL,
      services: {
        catalogSearch: {
          url: '/api/admin-joy/catalogs',
        },
        catalogItems: {
          url: '/api/admin-joy/catalog-items',
        },
      } 
    },
    slReportAPI: {
      rootUrl: process.env.VUE_APP_SL_REPORT_URL,
      token: process.env.VUE_APP_SL_REPORT_TOKEN,
      services: {
        reportDownload: {
          url: '/shipping_list'
        }
      }
    },
    ldgReportAPI: {
      rootUrl: process.env.VUE_APP_LDG_REPORT_URL,
      token: process.env.VUE_APP_LDG_REPORT_TOKEN,
      services: {
        reportDownload: {
          url: '/ldg_list'
        }
      }
    },
    ldgStockReportAPI: {
      rootUrl: process.env.VUE_APP_LDG_STOCK_REPORT_URL,
      token: process.env.VUE_APP_LDG_STOCK_REPORT_TOKEN,
      services: {
        reportDownload: {
          url: '/ldg_stock_list'
        }
      }
    },
    processInvetoryAPI: {
      rootUrl: process.env.VUE_APP_SL_REPORT_URL,
      token: process.env.VUE_APP_SL_REPORT_TOKEN,
      services: {
        reportDownload: {
          url: '/save_inventory'
        }
      }
    },
    ListInvetoryAPI: {
      rootUrl: process.env.VUE_APP_SL_REPORT_URL,
      token: process.env.VUE_APP_SL_REPORT_TOKEN,
      services: {
        reportDownload: {
          url: '/list_inventory'
        }
      }
    },
    DeleteInvetoryAPI: {
      rootUrl: process.env.VUE_APP_SL_REPORT_URL,
      token: process.env.VUE_APP_SL_REPORT_TOKEN,
      services: {
        reportDownload: {
          url: '/delete_inventory'
        }
      }
    },
    StockCatalogAPI: {
      rootUrl: process.env.VUE_APP_SL_REPORT_URL,
      token: process.env.VUE_APP_SL_REPORT_TOKEN,
      services: {
        reportDownload: {
          url: '/stock_catalog'
        }
      }
    },
  },
  getters: {
  },
  mutations: {
    // App
    setAppLoading(state, isLoading=false){
      state.app.isLoading = isLoading
    },
    setSideMenu(state, closed=false){
      if( closed )
        setTimeout(()=>state.app.isSideMenuOpen = closed, 100)
      else
        state.app.isSideMenuOpen = closed
    },
    setAppDarkTheme(state, active=false){
      state.app.isDarkThemeActive = active

      localStorage.setItem('mw.app.darkTheme', active ? 1 : 0)

      if( active )
        document.body.classList.add('dark-theme')
      else
        document.body.classList.remove('dark-theme')
    },
  },
  actions: {
    // App
    logout(){
      let vm = this._vm

      vm.$buefy.dialog.confirm({
          title: '¿Cerrar sesión?',
          message: 'Todas tus sesiones de otras plataformas se cerraran tambien.',
          onConfirm: () => vm.$keycloak.logoutFn()
      })
    },
    toggleSideMenu({commit, state}){
      commit('setSideMenu', !state.app.isSideMenuOpen)
    },
    toggleDarkTheme({commit, state}){
      commit('setAppDarkTheme', !state.app.isDarkThemeActive)
    },
    /* APIS */
    // AWS Backend
    getUploadedTemplatesLog({state}, fullQuery){
      let API = state.backendAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.uploadedTemplatesLog.url}${fullQuery}`)
    },
    getCatalogList({state}, fullQuery){
      let API = state.backendAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.catalogs.get.url}${fullQuery}`)
    },
    createCatalog({state}, catalogName){
      let API = state.backendAPI
      let user = this._vm.$keycloak.tokenParsed
      catalogName = btoa(catalogName)
      return window.HTTP.put(`${API.rootUrl}${API.services.catalogs.put.url}${catalogName}`, 
        {}, 
        {
          headers: {
            'x-user-email': user.email,
          }
        }
      )
    },
    getUploadedTemplateLogDetails({state}, fileId){
      let API = state.backendAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.uploadedTemplateLogDetails.url}/${fileId}`)
    },
    getS3ofUploadedTemplate({state}, fileId){
      let API = state.backendAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.s3LinkOfUploadedTemplate.url}/${fileId}.xlsx`)
    },
    getPreloadedTemplate({state}, rows){
      let API = state.backendAPI
      return window.HTTP.post(`${API.rootUrl}${API.services.preLoadedTemplate.url}`, rows)
    },
    getHistoryReport({state}, options){
      let API = state.backendAPI
      let path = API.services.reportGenerator.url
        .replace('{start}', options.start)
        .replace('{end}', options.end)

      return window.HTTP.get(`${API.rootUrl}${path}`)
    },
    // Motivale
    getCatalogAutocompleteList({state}, term){
      let API = state.motivaleRewardsAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.catalogSearch.url}?term=${term}`)
    },
    getCatalogItemList({state}, options){
      let API = state.motivaleRewardsAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.catalogItems.url}?catalogId=${options.catalogId}&type=${options.catalogType}`)
    },
    // SL Report
    getSLReport({state}, options){
      let API = state.slReportAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.reportDownload.url}?initDate=${options.start}&endDate=${options.end}`, {
        headers: {
          'x-api-key': API.token,
        }
      })
    },
    // LDG Report
    getLDGReport({state}, options){
      let API = state.ldgReportAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.reportDownload.url}?initDate=${options.start}&endDate=${options.end}`, {
        headers: {
          'x-api-key': API.token,
        }
      })
    },
    // LDG Stock Report
    getLDGStockReport({state}, options){
      let API = state.ldgStockReportAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.reportDownload.url}?initDate=${options.start}&endDate=${options.end}`, {
        headers: {
          'x-api-key': API.token,
        }
      })
    },
    // Process Inventory
    processInventory({state}, rows){
      let API = state.processInvetoryAPI
      console.log("Rows: ", rows);
      return window.HTTP.post(`${API.rootUrl}${API.services.reportDownload.url}`, rows,
        {
          headers: {
            'x-api-key': API.token,
          }
        }
      )
    },
    // List Inventory
    listInventory({state}){
      let API = state.ListInvetoryAPI
      return window.HTTP.get(`${API.rootUrl}${API.services.reportDownload.url}`, {
        headers: {
          'x-api-key': API.token,
        }
      })
    },
    // Delete Inventory
    deleteInventory({state}, options){
      let API = state.DeleteInvetoryAPI
      let url = `${API.rootUrl}${API.services.reportDownload.url}?id=${options.id}`
      console.log("URL: ", url);
      return window.HTTP.delete(url, {
        headers: {
          'x-api-key': API.token,
        }
      })
    },
    // Stock Catalog
    stockCatalog({state}, options){
      let API = state.StockCatalogAPI
      let url = `${API.rootUrl}${API.services.reportDownload.url}?catalogId=${options.catalogId}`
      console.log("URL: ", url);
      return window.HTTP.get(url, {
        headers: {
          'x-api-key': API.token,
        }
      })
    },
  },
  modules: {
  }
})
