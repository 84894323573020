<template>
    <nav class="has-background-primary has-text-light">
        <div class="mr-auto d-flex">
            <b-icon @click.native="$store.dispatch('toggleSideMenu')" icon="bars" size="is-medium" class="side-menu-toggle is-cursor-pointer mt-auto mb-auto mr-3"/>
        </div>
        <div class="ml-auto d-flex">
            <b-dropdown title="Perfil" aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                    <span class="is-cursor-pointer is-no-selectable">
                        {{ firstName | capitalize }} 
                        {{ lastName | capitalize }}
                    </span>
                    <b-icon :icon=" active ? 'chevron-up': 'chevron-down'" size="is-medium" class="is-cursor-pointer"/>
                </template>
                <b-dropdown-item aria-role="listitem" @click="$store.dispatch('logout')">
                    <b-icon icon="power-off" size="is-medium"/>
                    <span>Cerrar sesión</span>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            data: [],
            name: '',
            selected: null
        }
    },
    computed: {
        isDarkThemeActive(){
            return this.$store.state.app.isDarkThemeActive
        },
        kcTokenParsed(){
            return this.$keycloak.tokenParsed
        },
        firstName(){
            return this.kcTokenParsed ? this.kcTokenParsed.given_name : null 
        },
        lastName(){
            return this.kcTokenParsed ? this.kcTokenParsed.family_name : null
        }
    }
}
</script>

<style>
</style>