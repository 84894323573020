<template>
    <div class="box">
        <b-field label="Rango de fechas">
            <b-datepicker
                class="w-100"
                v-model="range"
                locale="es-ES"
                placeholder="Seleccionar"
                icon="calendar"
                :icon-right="range ? 'close-circle' : 'chevron-down'"
                icon-right-clickable
                @icon-right-click="clearDate"
                range>
            </b-datepicker>
            <p class="control">
                <b-button @click="getReportUrl" type="is-light is-primary" icon-left="file-arrow-down" label="Descargar" :disabled="!range"/>
            </p>
        </b-field>
    </div>
</template>

<script>
export default {
    data(){
        return {
            range: null,
            isLoading: false,
        }
    },
    methods: {
        clearDate(){
            this.range = null
        },
        getReportUrl(){
            if( this.range.length < 1 )return;

            this.isLoading = true
            this.$buefy.toast.open({
                message: 'Estamos obteniendo la liga del archivo...',
                position: 'is-bottom-right'
            })
            this.$store.dispatch('getHistoryReport', {
                start: this.range[0].toISOString().replace('T', ' ').replace('Z', '').slice(0, -4),
                end: this.range[1].toISOString().replace('T', ' ').replace('Z', '').slice(0, -4),
            })
            .then(res=>{
                if( res.data.s3linkreport )
                    window.open( res.data.s3linkreport )
            })
            .catch(err=>{
                console.warn(err)
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>this.isLoading = false)
        }
    }
}
</script>

<style>

</style>