<template>
    <div class="box overflow-x-auto">
        <b-table :data="catalogList" :loading="isLoading" default-sort="id" default-sort-direction="desc">
             <b-table-column field="id" label="Id" v-slot="props" centered sortable>
                <i class="is-size-7">
                    {{ props.row.id }}
                </i>
            </b-table-column>

            <b-table-column field="catalogId" label="UUID" v-slot="props" centered sortable>
                <i class="is-size-7">
                    {{ props.row.catalog_id }}
                </i>
            </b-table-column>

            <b-table-column field="body" label="Body" v-slot="props" centered>
                <a @click.prevent="exportToExcel(props.row.body)" class="tag is-primary is-light">
                    <b-icon icon="up-right-from-square"/>&nbsp;Excel
                </a>
            </b-table-column>


            <b-table-column field="version" label="Fecha y Hora de procesamiento" v-slot="props" centered sortable>
                <span class="tag">
                    {{ formatDateTime(props.row.date_deploy, props.row.hour_deploy) }}
                </span>
            </b-table-column>


            <b-table-column field="status" label="Estatus" v-slot="props" centered sortable>
                <span class="tag" :class="{
                    'is-success': props.row.process != null && !props.row.deleted,
                    'is-danger': props.row.deleted
                }">
                    {{ props.row.deleted ? 'Eliminado' : _getStatusDisplay(props.row.process) }}
                </span>
            </b-table-column>

            <b-table-column field="actions" label="Eliminar" v-slot="props" centered>
                <template v-if="!props.row.deleted && !props.row.process">
                    <button class="tag button is-danger" @click="showDeleteModal(props.row)">
                    <b-icon icon="trash" />
                    </button>
                </template>
                <template v-else>
                    <button class="tag button is-secondary" disabled>
                    <b-icon icon="check" />
                    </button>
                </template>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">Nada para mostrar</div>
            </template>
        </b-table>

    </div>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
    mounted(){
        this.getCatalogs()
    },
    data() {
        return {
            catalogList: [],
            isLoading: false,
            logEntriesPerPage: 100,
            isModalAddOpen: false,
            itemToDelete: null
        }
    },
    watch: {
        logEntriesPerPage(){
            window.debounce(this.getCatalogs, 300)
        }
    },
    methods: {
        exportToExcel(dataBody) {
            let data = []
            for (let i = 0; i < dataBody.length; i++) {
                let row = dataBody[i]
                data.push({
                    "productSku": row.productSku,
                    "cantidad": row.cantidad,
                })
            }

            // Crear una hoja de cálculo a partir del array
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventario');

            // Generar archivo Excel
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Crear un Blob a partir del buffer
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'inventario.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        },
        _getStatusDisplay(status){
            if(status != null){
                return 'Procesado'
            }else{
                return 'No procesado'
            }
        },
        showDeleteModal(item) {
            const confirmed = window.confirm('¿Estás seguro de que deseas eliminar este elemento?');
            if (confirmed) {
                this.handleDelete(item.id);
            }
        },
        confirmDelete() {
            if (this.itemToDelete) {
                this.handleDelete(this.itemToDelete.id);
                this.itemToDelete = null;
                this.$refs.deleteModal.hide();
            }
        },
        handleDelete(id) {
            this.$store.dispatch('deleteInventory', {
                id: id
            }).then(response => {
                if (response.status == 200) {
                this.getCatalogs();
                this.$buefy.toast.open({
                    message: '¡Catálogo eliminado!',
                    type: 'is-success'
                });
                }
            });
        },
        formatDate(dateString){
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(dateString).toLocaleDateString(undefined, options);
        },
        formatTime(dateString){
            const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return new Date(dateString).toLocaleTimeString(undefined, options);
        },
        formatDateTime(dateString, timeString){
            return this.formatDate(dateString) + ' ' + this.formatTime(timeString)
        },
        createNew(ev){
            let form = ev.target
            let catalogName = form.querySelector('[name="name"]').value
            this.isLoading = true

            this.$store.dispatch('createCatalog', catalogName)
            .then(response=>{
                if(response.status == 201){
                    this.getCatalogs()
                    form.reset()
                    this.isModalAddOpen = false
                    this.$buefy.toast.open({
                        message: '¡Nombre de catálogo registrado!',
                        type: 'is-success'
                    })
                }
            }).catch(err=>{
                console.error(err)
                this.$buefy.toast.open({
                    message: `Error: ${err.toString()}`,
                    type: 'is-danger'
                })
            })
            .finally(()=>this.isLoading=false)
        },
        getCatalogs(){
            this.isLoading = true
            this.$store.dispatch(
                'listInventory', {}
            )
            .then(res=>{
                console.log("res-----------------", res.data);
                let data = res.data
                this.catalogList = data.inventory
            })
            .catch(err=>{
                console.log("err-----------------", err);
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>{
                this.isLoading = false
            })
        },
    }
}
</script>

<style>
.button.is-danger {
  background-color: red;
  color: white;
}
</style>