<template>
  <div id="app">
    
    <!-- Panel -->
    <div v-if="isDashboardRoute" class="wrapper d-flex">
      <side-menu/>
      <main>
        <top-menu/>
        <div class="panel-content">
          <h1 class="title is-3">{{ routeViewTitle }}</h1>
          <h1 class="subtitle is-6 has-text-grey">{{ routeViewSubtitle }}</h1>
          <b-breadcrumb size="is-small">
            <b-breadcrumb-item 
              v-for="subLink in routeBreadcrumb" 
              :key="subLink.path"
              tag='router-link' 
              :to="subLink.path">
                {{ subLink.display }}
            </b-breadcrumb-item>
          </b-breadcrumb>
          <router-view/>
        </div>
      </main>
    </div>
    <!-- Panel -->

    <!-- Other pages -->
    <div v-else>
      <router-view/>
    </div>
    <!-- Other pages -->

    <!-- App status preloader -->
    <b-loading 
      v-model="isAppLoading" 
      :is-full-page="true" 
      :can-cancel="false">
    </b-loading>
    <!-- App status preloader -->

  </div>
</template>

<script>
import topMenu from '@/components/global/TopMenu'
import sideMenu from '@/components/global/SideMenu'
export default {
  head: {
    title: {
      inner: 'Admin JOY 2.0'
    },
    script: [
      { 
        type: 'text/javascript', 
        src:'https://kit.fontawesome.com/112ca4ddd7.js', 
        async: true, 
        body: true
      },
    ],
  },
  components: {
    topMenu,
    sideMenu,
  },
  computed: {
    isAppLoading(){
      return this.$store.state.app.isLoading
    },
    routeMeta(){
      return this.$route.meta ? ( typeof this.$route.meta == 'function' ? this.$route.meta(this.$route) : this.$route.meta ) : null
    },
    routeViewTitle(){
      return this.routeMeta.viewTitle || 'Sección'
    },
    routeViewSubtitle(){
      return this.routeMeta.viewSubtitle || ''
    },
    routeBreadcrumb(){
      return this.routeMeta.breadcrumb || []
    },
    isDashboardRoute(){
      return this.routeMeta.requiresAuth
    },
    isDarkThemeActive(){
      return this.$store.state.app.isDarkThemeActive
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/base/app';
</style>
