<template>
    <div>

        <div class="box">
            <b-field label="Rango de fechas">
                <b-datepicker
                class="w-100"
                v-model="range"
                locale="es-ES"
                placeholder="Seleccionar"
                icon="calendar"
                :icon-right="range ? 'close-circle' : 'chevron-down'"
                icon-right-clickable
                @icon-right-click="clearDate"
                :loading="isLoading"
                :disabled="isLoading"
                range>
            </b-datepicker>
            <p class="control">
                <b-button 
                @click="getReport" 
                type="is-light is-primary" 
                icon-left="file-arrow-down" 
                label="Descargar" 
                :disabled="!range"
                :loading="isLoading"
                />
            </p>
        </b-field>
    </div>

    <br/>
    <br/>
    <br/>
    <br/>

    <!-- LDG stock list -->
    <h1 class="title is-4">Reporte de stock LDG</h1>
    <div class="box">
            <b-field label="Rango de fechas">
                <b-datepicker
                class="w-100"
                v-model="range"
                locale="es-ES"
                placeholder="Seleccionar"
                icon="calendar"
                :icon-right="range ? 'close-circle' : 'chevron-down'"
                icon-right-clickable
                @icon-right-click="clearDate"
                :loading="isLoading"
                :disabled="isLoading"
                range>
            </b-datepicker>
            <p class="control">
                <b-button 
                @click="getReport" 
                type="is-light is-primary" 
                icon-left="file-arrow-down" 
                label="Descargar" 
                :disabled="!range"
                :loading="isLoading"
                />
            </p>
        </b-field>
    </div>

</div>
</template>

<script>
export default {
    data(){
        return {
            range: null,
            isLoading: false,
        }
    },
    methods: {
        clearDate(){
            this.range = null
        },
        getReport(){
            if( this.range.length < 1 )return;

            let start = this.range[0]
            let end = this.range[1]
            
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if( diffDays > 30 )
                return this.$buefy.toast.open({
                    message: 'Puedes descargar hasta 30 días de info',
                    position: 'is-bottom-right'
                })


            this.isLoading = true
            this.$buefy.toast.open({
                message: 'Estamos obteniendo tu reporte...',
                position: 'is-bottom-right'
            })

            this.$store.dispatch('getLDGReport', {
                start: ('0' + start.getDate().toString()).slice(-2) + 
                    ('0' + (start.getMonth()+1).toString()).slice(-2) + 
                    start.getFullYear().toString(),

                end: ('0' + end.getDate().toString()).slice(-2) + 
                    ('0' + (end.getMonth()+1).toString()).slice(-2) +  
                    end.getFullYear().toString()
            })
            .then(res=>{
                let data = res.data
                if(!data)
                    return this.$buefy.toast.open({
                        message: 'Sin información para descargar!',
                        position: 'is-bottom-right'
                    })

                this.csvStringToFileDownload(data)
            })
            .catch(err=>{
                console.warn(err)
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>this.isLoading = false)
        },
        csvStringToFileDownload(data){
            const blob = new Blob(["\uFEFF"+data], { type: 'text/csv;charset=utf-8' });
            const url = window.URL.createObjectURL(blob)

            window.open(url)
        }
    }
}
</script>

<style>

</style>