<template>
    <div class="p-relative">
        <b-loading v-model="isLoadingLogEntryItems"/>
        <div class="box" ref="view">
            <table class="table table-properties is-bordered is-striped w-100">
                <tr>
                    <td>UUID Catálogo</td>
                    <td>{{ this.$route.params.id }}</td>
                </tr>
                <tr>
                    <td>Órdenes procesadas</td>
                    <td>{{ details.processed_order_products }}</td>
                </tr>
                <tr>
                    <td>Órdenes de pepsico</td>
                    <td>{{ details.pepsico_order_products }}</td>
                </tr>
                <tr v-if="hasErrors">
                    <td class="has-background-danger has-text-light">Errores</td>
                    <td>
                        <ul>
                            <li>{{ details.info }}</li>
                            <li>{{ details.info_l2a }}</li>
                            <li>{{ details.info_response }}</li>
                        </ul>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        this.getStockCatalog()
    },
    data() {
        return {
            details: {},
            isLoadingLogEntryItems: false,        
        }
    },
    computed: {
        hasErrors(){
            return this.details.info || 
                this.details.info_l2a ||
                this.details.info_response
        }
    },
    methods: {
        getStockCatalog(){
            this.isLoadingLogEntryItems = true
            console.log("this.$route.params.id-----", this.$route.params.id);
            
            let catalogId = this.$route.params.id
            this.$store.dispatch('stockCatalog',{
                catalogId
            })
            .then(res=>{
                console.log("res-----", res);
                console.log("resdata-----", res.data);
                let data = res.data
                this.details = data
            })
            .catch(err=>{
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>{
                this.isLoadingLogEntryItems = false
            })
        },
    }
}
</script>