<template>
    <div class="p-relative">
        <b-loading v-model="isLoadingLogEntryItems"/>
        <div class="box" ref="view">
            <b-message 
                :type="statusType"
                size="is-default"
                has-icon>
                <p>
                    {{ statusDisplay }}
                </p>
            </b-message>
            <table class="table table-properties is-bordered is-striped w-100">
                <tr>
                    <td>Catálogo</td>
                    <td>{{ details.catalogName }}</td>
                </tr>
                <tr>
                    <td>UUID Catálogo</td>
                    <td>{{ details.uuidCatalogName }}</td>
                </tr>
                <tr>
                    <td>Versión del catálogo</td>
                    <td>{{ details.catalogVersion }}</td>
                </tr>
                <tr>
                    <td>Fecha de creación</td>
                    <td>{{ details.createdAt }}</td>
                </tr>
                <tr>
                    <td>Fecha de actualización</td>
                    <td>{{ details.updatedAt }}</td>
                </tr>
                <tr>
                    <td>Tipo de carga</td>
                    <td>{{ details.filetype }}</td>
                </tr>
                <tr>
                    <td>Usuario</td>
                    <td>{{ details.userEmail }}</td>
                </tr>
                <tr>
                    <td>Items cargados</td>
                    <td>{{ details.itemsLoaded }}</td>
                </tr>
                <tr>
                    <td>File URI</td>
                    <td>{{ details.file_uri }}</td>
                </tr>
                <tr v-if="hasErrors">
                    <td class="has-background-danger has-text-light">Errores</td>
                    <td>
                        <ul>
                            <li>{{ details.info }}</li>
                            <li>{{ details.info_l2a }}</li>
                            <li>{{ details.info_response }}</li>
                        </ul>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        this.getLogDetails()
    },
    data() {
        return {
            details: {},
            isLoadingLogEntryItems: false,        
        }
    },
    computed: {
        statusType(){
            return {
                'PROCESSING': 'is-info',
                'PROCESSED': 'is-success',
                'FILEUPLOADSUCCESS': 'is-success',
                'ERROR': 'is-danger',
                'FILEVALIDATIONERROR': 'is-danger',
                'PROCESSEDWITHERROR': 'is-warning',
            }[this.details.status]
        },
        statusDisplay(){
            return {
                'PROCESSING': 'El archivo cargado se esta procesando',
                'PROCESSED': 'El archivo cargado fué Procesado',
                'FILEUPLOADSUCCESS': 'El archivo fue subido con éxito',
                'ERROR': 'El archivo tuvo errores al cargarse',
                'FILEVALIDATIONERROR': 'Error archivo tuvo errores de validación al cargarse',
                'PROCESSEDWITHERROR': 'El archivo fue procesado con errores',
            }[this.details.status]
        },
        hasErrors(){
            return this.details.info || 
                this.details.info_l2a ||
                this.details.info_response
        }
    },
    methods: {
        getLogDetails(fileId=null){
            this.isLoadingLogEntryItems = true
            let logDetailsId = this.$route.params.id
            this.$store.dispatch('getUploadedTemplateLogDetails', fileId || logDetailsId)
            .then(res=>{
                let data = res.data
                this.details = data
            })
            .catch(err=>{
                this.$buefy.toast.open({
                    type: 'is-danger',
                    message: 'Hubo un error: ' + err.toString(),
                    position: 'is-bottom-right'
                })
            })
            .finally(()=>{
                this.isLoadingLogEntryItems = false
            })
        },
    }
}
</script>